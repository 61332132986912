<template>
  <div class="main">


    <div class="main__top">
      <div
          @click="category_show=true"
          class="add__btn">
        <p>Добавить категорию</p>
        <i

            class="mdi mdi-plus">
        </i>
      </div>
      <i
          @click="$router.push('/cash')"
          class="back mdi mdi-keyboard-backspace">
      </i>
    </div>


    <div class="products">

      <div
          v-for="(category,index) in categories"
          :key="index"
          class="product__item"
      >
        <p v-if="category.name"><b>Название:</b>{{ category.name }}</p>
      </div>
    </div>

    <v-dialog width="50%" v-model="product_delete">
      <v-card >

        <div class="product__deletion">

          <h3>Удалить товар?</h3>

          <div class="product__deletion__rows">
            <v-btn
                type="submit"
                class="primary mr-2"
                @click="deleteProductAction()"
            >
              <p>Да</p>
            </v-btn>
            <v-btn
                type="submit"
                class="red"
                @click="product_delete=false"
            >
              <p>Нет</p>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog width="50%" v-model="category_show">
      <v-card >
        <div class="pa-5 item__column product_control">
          <p class="product_control__title">Добавить категорию</p>

          <div class="item__column product__control__label">
            <p >Название</p>
            <input v-model="name" required/>
          </div>

          <button
              type="submit"
              class="product__control__btn item__pointer"
              @click="saveCategory()"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>

export default {
  name: "Mailing",
  components: {

  },
  data() {
    return {
      categories: [],
      product_delete: false,
      product_id: '',

      product_show: false,
      category_show: false,

    }
  },
  methods: {
    getCategories() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
      .then((res) => {
        this.categories = res.data
      })
      .catch((error) => {
        console.log(error)
      });
    },
    deleteProductAction() {

      this.$axios({
        method: 'delete',
        url: `${this.$API_URL}product/${this.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    deleteProduct(product) {
      this.product_delete = true
      this.product_id = product.id
    },
    editProduct(product) {
      this.product_show = true
      this.name = product.name
      this.description = product.description
    },
    saveCategory() {
      let data = {
        name: this.name
      }
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}category`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.category_show = false

            this.getCategories()
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    saveProduct() {
      let data = {
        name: this.name,
        quantity: this.quantity,
        price: this.price,
        count_type: this.count_type,
        description: this.description
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}product`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    }
  },
  mounted() {
    this.getCategories()
  },
  watch: {
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  textarea {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }
  button {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;
    p {
      margin-bottom: 0;
      font-weight: bold;
      color: white;
    }
  }
}

.product_control {
  input,textarea {
    border: 1px solid black;
    padding: 15px;
  }

  .product__control__label {
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .product__control__btn {
    padding: 10px;
    background: #0069ff;
    color: white;
    p {
      margin-bottom: 0;
    }
  }

  .product_control__title {
    font-size: 24px;
  }
}

.product__item {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin-bottom: 15px;

  b {
    margin-right: 5px;
  }

  .product__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      i {
        font-size: 18px;
      }
    }

    div:hover {
      opacity: 0.8;
    }
  }
}

.product__deletion {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;


  padding: 20px;

  .product__deletion__rows {
    display: flex;
    flex-direction: row;
    margin-top: 30px;

  }
  button {
    padding: 10px;
    background: #0069ff;
    color: white;

    p {
      margin-bottom: 0;

    }
  }
}

.main__top {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  i:hover {
    opacity: 0.8;
  }
  .back {
    font-size: 32px;
  }
  .back:hover {
    color: orangered;
  }
}

.add__btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #0069ff;
  padding: 5px;
  border-radius: 5px;
  color: white;

  p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.products {
  padding: 45px;
}

.main {
  padding: 20px;
}
</style>
